.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  align-items: center;
  padding: 0 5px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  position: fixed;
  width: 100%;
  z-index: 999;
  background: var(--yellow);
  box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
}

.header__icons {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media screen and (min-width: 900px) {
  .menuIcon {
    display: none;
  }

  .header_logo_mobile {
    display: none;
  }
}

.header_logo_mobile {
  display: none;
}

@media screen and (max-width: 900px) {
  .header_logo_mobile {
    display: block;
  }
}
