.main {
  padding: 50px 0px;
  display: block;
}

@media screen and (min-width: 900px) {
  .drawerMenuIcon {
    display: none;
  }
}

.drawer_logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* drawer */
.drawer {
  background-color: var(--white);
  height: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin: 10px 8px;
}

@media screen and (min-width: 900px) {
  .main_children {
    margin-left: 280px;
  }
}
